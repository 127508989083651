<template>
  <div id="verificationReport">
    <el-dialog
      :title="verificationReportFormTitle"
      width="880px"
      :visible.sync="verificationReportDialogVisible"
      :close-on-click-modal="false"
      @close="verificationReportDialogClose"
    >
      <el-form
        ref="verificationReportFormRef"
        :model="verificationReportForm"
        :rules="verificationReportFormRules"
        label-position="right"
        label-width="200px"
      >
        <el-row>
          <el-col :span="12">
            <el-form-item label="产品名称" prop="productName">
              <el-input v-model="verificationReportForm.productName" placeholder="请输入产品名称" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="产品代号" prop="productNo">
              <el-input v-model="verificationReportForm.productNo" placeholder="请输入产品代号" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="最终样品设计评审的全部内容（最终样品设计评审报告的再确认）" prop="verificationItem1">
              <el-input
                v-model="verificationReportForm.verificationItem1"
                placeholder="请输入最终样品设计评审的全部内容（最终样品设计评审报告的再确认）"
                type="textarea"
                :rows="6"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="对设计风险和过程风险的风险分析进行综合确认" prop="verificationItem2">
              <el-input
                v-model="verificationReportForm.verificationItem2"
                placeholder="请输入对设计风险和过程风险的风险分析进行综合确认"
                type="textarea"
                :rows="6"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="对最终样品进行全项目试验（根据策划的产品技术要求全性能项目进行检验）" prop="verificationResults">
              <el-input
                v-model="verificationReportForm.verificationResults"
                placeholder="请输入对最终样品进行全项目试验（根据策划的产品技术要求全性能项目进行检验）"
                type="textarea"
                :rows="6"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="验证结果总结" prop="verificationItem3">
              <el-input
                v-model="verificationReportForm.verificationItem3"
                placeholder="请输入验证结果总结"
                type="textarea"
                :rows="6"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="设计开发验证负责人" prop="principal">
              <el-input v-model="verificationReportForm.principal" placeholder="请输入设计开发验证负责人" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="日期" prop="signatureDate">
              <el-date-picker v-model="verificationReportForm.signatureDate" placeholder="请选择日期" value-format="yyyy-MM-dd" />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer">
        <el-button @click="verificationReportDialogVisible = false">
          取 消
        </el-button>
        <el-button type="primary" @click="verificationReportFormSubmit">
          确 定
        </el-button>
      </div>
    </el-dialog>
    <el-form inline size="small">
      <el-form-item label="产品名称">
        <el-input v-model="searchForm.productName" placeholder="请输入产品名称" clearable />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="handleSearch">
          搜索
        </el-button>
        <el-button type="primary" icon="el-icon-plus" @click="handleAdd">
          新增
        </el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="verificationReportPage.list"
      row-key="id"
      :cell-style="{padding: '6px'}"
      :header-cell-style="{background: '#f8f8f9'}"
      :height="getTableHeight()"
    >
      <el-table-column prop="productName" label="产品名称" />
      <el-table-column prop="productNo" label="产品代号" />
      <el-table-column prop="verificationItem1" label="最终样品设计评审的全部内容" />
      <el-table-column prop="verificationItem2" label="对设计风险和过程风险的风险分析进行综合确认" />
      <el-table-column prop="verificationItem3" label="对最终样品进行全项目试验" />
      <el-table-column prop="verificationResults" label="验证结果总结" />
      <el-table-column prop="principal" label="设计开发验证负责人" />
      <el-table-column label="日期">
        <template slot-scope="scope">
          <span v-if="scope.row.signatureDate">{{ scope.row.signatureDate.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button
            type="text"
            icon="el-icon-delete"
            size="small"
            @click.stop="handleDelete(scope.$index, scope.row)"
          >
            删除
          </el-button>
          <el-button
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleUpdate(scope.$index, scope.row)"
          >
            修改
          </el-button>
          <el-button
            type="text"
            icon="el-icon-info"
            size="small"
            @click.stop="handleInfo(scope.$index, scope.row)"
          >
            详情
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :total="verificationReportPage.total"
      :current-page="searchForm.pageNum"
      :page-size="searchForm.pageSize"
      :page-sizes="[10, 15, 20]"
      layout="total, sizes, prev, pager, next, jumper"
      background
      @current-change="pageNumChange"
      @size-change="pageSizeChange"
    />
  </div>
</template>

<script>
import { addVerificationReport, deleteVerificationReport, updateVerificationReport, selectVerificationReportInfo, selectVerificationReportList } from '@/api/technology/verificationReport'

export default {
  data () {
    return {
      verificationReportDialogVisible: false,
      verificationReportFormTitle: '',
      verificationReportForm: {
        id: '',
        productName: '',
        productNo: '',
        verificationItem1: '',
        verificationItem2: '',
        verificationItem3: '',
        verificationResults: '',
        principal: '',
        signatureDate: ''
      },
      verificationReportFormRules: {
        productName: [{ required: true, message: '产品名称不能为空', trigger: ['blur', 'change']}]
      },
      verificationReportPage: {
        list: [],
        total: 0
      },
      searchForm: {
        pageNum: 1,
        pageSize: 10,
        productName: ''
      }
    }
  },
  created () {
    selectVerificationReportList(this.searchForm).then(res => {
      this.verificationReportPage = res
    })
  },
  methods: {
    verificationReportDialogClose () {
      this.$refs.verificationReportFormRef.resetFields()
    },
    verificationReportFormSubmit () {
      if (this.verificationReportFormTitle === '设计开发验证报告详情') {
        this.verificationReportDialogVisible = false
        return
      }
      this.$refs.verificationReportFormRef.validate(async valid => {
        if (valid) {
          if (this.verificationReportFormTitle === '新增设计开发验证报告') {
            await addVerificationReport(this.verificationReportForm)
          } else if (this.verificationReportFormTitle === '修改设计开发验证报告') {
            await updateVerificationReport(this.verificationReportForm)
          }
          this.verificationReportPage = await selectVerificationReportList(this.searchForm)
          this.verificationReportDialogVisible = false
        }
      })
    },
    handleAdd () {
      this.verificationReportFormTitle = '新增设计开发验证报告'
      this.verificationReportDialogVisible = true
    },
    handleDelete (index, row) {
      this.$confirm('确认删除？', '提示', {
        type: 'warning'
      }).then(async () => {
        await deleteVerificationReport(row.id)
        if (this.verificationReportPage.list.length === 1 && this.searchForm.pageNum > 1) {
          this.searchForm.pageNum--
        }
        this.verificationReportPage = await selectVerificationReportList(this.searchForm)
      })
    },
    handleUpdate (index, row) {
      this.verificationReportFormTitle = '修改设计开发验证报告'
      this.verificationReportDialogVisible = true
      this.selectVerificationReportInfoById(row.id)
    },
    handleInfo (index, row) {
      this.verificationReportFormTitle = '设计开发验证报告详情'
      this.verificationReportDialogVisible = true
      this.selectVerificationReportInfoById(row.id)
    },
    selectVerificationReportInfoById (id) {
      selectVerificationReportInfo(id).then(res => {
        this.verificationReportForm.id = res.id
        this.verificationReportForm.productName = res.productName
        this.verificationReportForm.productNo = res.productNo
        this.verificationReportForm.verificationItem1 = res.verificationItem1
        this.verificationReportForm.verificationItem2 = res.verificationItem2
        this.verificationReportForm.verificationItem3 = res.verificationItem3
        this.verificationReportForm.verificationResults = res.verificationResults
        this.verificationReportForm.principal = res.principal
        this.verificationReportForm.signatureDate = res.signatureDate
      })
    },
    handleSearch () {
      this.searchForm.pageNum = 1
      selectVerificationReportList(this.searchForm).then(res => {
        this.verificationReportPage = res
      })
    },
    pageNumChange (pageNum) {
      this.searchForm.pageNum = pageNum
      selectVerificationReportList(this.searchForm).then(res => {
        this.verificationReportPage = res
      })
    },
    pageSizeChange (pageSize) {
      this.searchForm.pageSize = pageSize
      this.searchForm.pageNum = 1
      selectVerificationReportList(this.searchForm).then(res => {
        this.verificationReportPage = res
      })
    }
  }
}
</script>

<style>
</style>
